<div class="m-portlet">
    <form [formGroup]="documentsForm" (ngSubmit)="onSubmitAddDocument()" novalidate>
        <div class="m-portlet__head pr-1">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title d-flex align-items-center">
                    <h3 class="m-portlet__head-text mb-0">{{ settings?.title }}</h3>
                </div>
            </div>
            <div *ngIf="settings?.canUpload && !isEmbeddedPreviewOpen" class="m-portlet__head-tools">
                <ul role="tablist" style="border: none" class="nav nav-tabs m-tabs m-tabs-line m-tabs-line--right m-tabs-line-danger">
                    <li *ngIf="!isVisibleInput" class="nav-item m-tabs__item mr-2 mf-cursor-pointer">
                        <a (click)="isVisibleInput = !isVisibleInput" aria-selected="false" class="nav-link m-tabs__link border-0">
                            <i class="la la-plus"></i>
                            <span class="m--font-brand"></span>
                        </a>
                    </li>
                    <li *ngIf="isVisibleInput" class="nav-item m-tabs__item mr-2 mf-cursor-pointer">
                        <a (click)="isVisibleInput = !isVisibleInput" aria-selected="false" class="nav-link m-tabs__link border-0">
                            <i class="la la-minus"></i>
                            <span class="m--font-brand"></span>
                        </a>
                    </li>
                </ul>
            </div>
            <div
                *ngIf="settings?.embeddedPreviewEnabled && isEmbeddedPreviewOpen"
                class="d-flex align-self-center mf-cursor-pointer justify-content-end h-100 align-items-center mr-2"
            >
                <a
                    (click)="onEmbeddedPreviewClose()"
                    aria-selected="false"
                    ngbTooltip="Pomanjšaj"
                    class="btn btn-outline-primary m-btn m-btn--icon btn-sm m-btn--icon-only m-btn--pill"
                >
                    <i class="la la-compress"></i>
                    <span class="m--font-brand"></span>
                </a>
            </div>
        </div>
        <div class="m-portlet__body">
            <div *ngIf="isVisibleInput && !isEmbeddedPreviewOpen" [@expandCollapseHeight] class="upload">
                <div class="m-list-search pb-2">
                    <div class="m-list-search__results">
                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i ngbTooltip="Ime datoteke" class="la la-paperclip m--font-inverse-light"></i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                <div class="custom-file">
                                    <input
                                        (change)="selectFile($event)"
                                        type="file"
                                        formControlName="uploadFile"
                                        value=""
                                        class="custom-file-input"
                                    />
                                    <label *ngIf="!selectedFilesForUpload" for="customFile" style="overflow: hidden" class="custom-file-label">
                                        Izberi datoteko
                                    </label>
                                    <label *ngIf="selectedFilesForUpload" for="customFile" style="overflow: hidden" class="custom-file-label">
                                        {{ selectedFilesForUpload[0]?.name }}
                                    </label>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div *ngIf="percentDone" class="progress m-progress--sm">
                        <div
                            [style.width.%]="percentDone"
                            aria-valuemax="100"
                            aria-valuemin="0"
                            aria-valuenow="100"
                            role="progressbar"
                            class="progress-bar m--bg-primary"
                        ></div>
                    </div>

                    <button
                        type="submit"
                        class="m--align-right mt-2 mb-2 btn btn-outline-accent m-btn m-btn--custom m-btn--icon m-btn--pill btn-block"
                    >
                        <span>
                            <i class="la la-check"></i>
                            <span>Naloži</span>
                        </span>
                    </button>
                </div>
                <hr />
            </div>
            <div *ngIf="!isEmbeddedPreviewOpen" class="m-widget4">
                <ng-container *ngIf="documents$ | async as docs; else noDocumentsTemplate">
                    <ng-container *ngIf="docs.length > 0; else noDocumentsTemplate">
                        <div *ngFor="let doc of docs" class="m-widget4 d-flex flex-row mb-1">
                            <common-document-list-element
                                [document]="doc"
                                [isReadOnly]="doc.isReadOnly"
                                [showCreationLocation]="true"
                                (onOpenDocument)="openMediaViewDialog(doc)"
                                (onDelete)="removeDocumetFromlist(doc)"
                                (onDownloadDocument)="downloadDocument(doc)"
                                class="document-element viewer-list-item mf-cursor-pointer"
                            ></common-document-list-element>

                            <div *ngIf="settings?.embeddedPreviewEnabled" class="d-flex align-self-center mf-cursor-pointer">
                                <a
                                    (click)="onEmbeddedPreviewOpen(doc)"
                                    aria-selected="false"
                                    ngbTooltip="Povečaj"
                                    class="btn btn-outline-primary m-btn m-btn--icon btn-sm m-btn--icon-only m-btn--pill"
                                >
                                    <i class="la la-expand"></i>
                                    <span class="m--font-brand"></span>
                                </a>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div *ngIf="settings?.embeddedPreviewEnabled && isEmbeddedPreviewOpen">
                <ng-container *ngIf="embeddedPreviewDocument$ | async as docData">
                    <app-media-view-popup
                        *ngIf="docData.state === ObjectState.VALID"
                        [print]="docData.value.print"
                        [setdocumentFile]="docData.value.data"
                        [fileName]="docData.value.filename"
                        [documentType]="docData.value.type"
                        [signature]="docData.value.signature"
                        [navigationButtons]="docData.value.navigationButtons"
                        [zoomOnly]="true"
                        [hideClose]="true"
                    ></app-media-view-popup>
                </ng-container>
            </div>
        </div>
    </form>
</div>

<ng-template #noDocumentsTemplate>Ni dokumentov.</ng-template>
