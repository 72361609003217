<div (keyup.arrowright)="activeModal.close('next')" (keyup.arrowleft)="activeModal.close('previous')" class="body">
    <div (click)="activeModal.dismiss('close-no-error'); (false)" class="mf-view-tools">
        <div class="d-flex flex-wrap justify-content-center">
            <a
                *ngIf="navigationButtons"
                (click)="activeModal.close('previous'); (false)"
                placement="top"
                ngbTooltip="Prejšnji"
                tooltipClass="d-none d-lg-block"
                href="#"
                class="btn btn-accent m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                i18n-ngbTooltip="@@21838662991872"
            >
                <i class="la la-arrow-left"></i>
            </a>
            <a
                *ngIf="!zoomOnly && (documentType === 'application/pdf' || qrSettings)"
                (click)="printFile(); $event.stopPropagation(); (false)"
                openDelay="100"
                placement="top"
                ngbTooltip="Natisni dokument"
                tooltipClass="d-none d-lg-block"
                href="#"
                class="btn btn-accent m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                [ngClass]="{ disabled: !pdfFullyLoaded && !qrSettings }"
                i18n-ngbTooltip="@@1d6b77eed4356a429f548ea2e3c3b3c2be28f165"
            >
                <i class="la la-print"></i>
            </a>
            <a
                *ngIf="
                    !zoomOnly &&
                    !(
                        documentType != 'application/pdf' &&
                        !documentType.includes('video') &&
                        documentType != 'text/plain' &&
                        !documentType.includes('image') &&
                        !qrSettings
                    )
                "
                (click)="downloadFile(); $event.stopPropagation(); (false)"
                placement="top"
                ngbTooltip="Prenesi dokument"
                tooltipClass="d-none d-lg-block"
                href="#"
                class="btn btn-accent m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                i18n-ngbTooltip="@@f08d18f3d6cf73fb7a56a12dd237d7f4641f6235"
            >
                <i class="la la-download"></i>
            </a>
            <a
                *ngIf="documentType === 'application/pdf' || documentType.includes('image')"
                (click)="zoom_in(); $event.stopPropagation(); (false)"
                placement="top"
                href="#"
                ngbTooltip="Povečaj"
                tooltipClass="d-none d-lg-block"
                class="btn btn-accent m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
            >
                <i class="la la-plus"></i>
            </a>
            <a
                *ngIf="documentType === 'application/pdf' || documentType.includes('image')"
                (click)="zoom_out(); $event.stopPropagation(); (false)"
                placement="top"
                href="#"
                ngbTooltip="Pomanjšaj"
                tooltipClass="d-none d-lg-block"
                class="btn btn-accent m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
            >
                <i class="la la-minus"></i>
            </a>
            <a
                *ngIf="!zoomOnly && documentType === 'application/pdf' && !fullScreenEnabled"
                (click)="fullscreen(); $event.stopPropagation(); (false)"
                placement="top"
                href="#"
                ngbTooltip="Razširi"
                tooltipClass="d-none d-lg-block"
                class="btn btn-accent m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                i18n-ngbTooltip="@@4660444245524480"
            >
                <i class="la la-expand"></i>
            </a>
            <a
                *ngIf="documentType === 'application/pdf' && fullScreenEnabled"
                (click)="fullscreen(); $event.stopPropagation(); (false)"
                placement="top"
                href="#"
                ngbTooltip="Skrči"
                tooltipClass="d-none d-lg-block"
                class="btn btn-accent m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                i18n-ngbTooltip="@@2058573152518144"
            >
                <i class="la la-compress"></i>
            </a>
            <a
                *ngIf="!hideClose"
                (click)="activeModal.dismiss('close-no-error'); (false)"
                placement="top"
                ngbTooltip="Zapri"
                tooltipClass="d-none d-lg-block"
                href="#"
                class="btn btn-danger m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                i18n-ngbTooltip="@@8afdc2ec5865741222cfce5348926af2f3a625f8"
            >
                <i class="la la-close"></i>
            </a>
            <a
                *ngIf="navigationButtons"
                (click)="activeModal.close('next'); (false)"
                placement="top"
                ngbTooltip="Naslednji"
                tooltipClass="d-none d-lg-block"
                href="#"
                class="btn btn-accent m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                i18n-ngbTooltip="@@7210698381721600"
            >
                <i class="la la-arrow-right"></i>
            </a>
        </div>
    </div>
    <!-- IFRAME FOR PRINTING -->
    <iframe #blob frameborder="0" style="left: -9999px; opacity: 0; height: 0px"></iframe>
    <!-- PDF -->
    <div *ngIf="documentType === 'application/pdf'" (click)="clickEvent($event)" id="pdf" style="overflow: auto" class="test">
        <div *ngIf="!checkIfEmpty(signature)" class="mf-digital-signature-seal">
            <div class="d-flex">
                <div>
                    <i style="height: 30px" class="la la-shield pt-1"></i>
                </div>
                <div>
                    <div>
                        <span>Podpisal:</span>
                        <span class="m--font-boldest">{{ signature?.signer }}</span>
                    </div>
                    <div>
                        <span>Datum:</span>
                        <span class="m--font-boldest">{{ signature?.date }}</span>
                    </div>
                    <div>
                        <span>IZS:</span>
                        <span class="m--font-boldest">{{ signature?.contractor }}</span>
                    </div>
                </div>
            </div>
        </div>
        <pdf-viewer
            *ngIf="fileReady"
            [src]="encryptedFile ? { url: documentFile, password: password } : documentFile"
            [fit-to-page]="true"
            [original-size]="false"
            [zoom]="zoom"
            (after-load-complete)="pdfLoadingComplete($event)"
            (error)="showError($event)"
            style="display: block"
        ></pdf-viewer>
    </div>
    <!-- IMAGE -->
    <div *ngIf="documentType.includes('image')" (click)="clickEvent($event)" class="mf-image-view">
        <img [src]="documentFile | safe: 'url'" [ngStyle]="{ width: widthPic }" id="img" class="center" />
    </div>
    <!-- ENCRYPTED PDF -->
    <div
        *ngIf="failedPassword"
        style="background-color: white; border-radius: 10px"
        class="text-warning text-center p-4 col-12 col-md-6 col-xl-3 ml-auto mr-auto mt-3"
    >
        <div class="m-2">
            <span style="font-size: 150%" i18n="media.view.document.">Dokument je zaklenjen</span>
        </div>
        <div class="m-2">
            <span><i class="la la-lock la-5x"></i></span>
        </div>
        <div>
            <button (click)="reEnterPassword()" class="btn btn-accent m-btn m-btn--pill m-btn--custom m-btn--air">Vnesi geslo</button>
        </div>
    </div>
    <!-- TEXT FILE-->
    <div *ngIf="documentType === 'text/plain'" (click)="clickEvent($event)" class="mf-text-plain-view p-3 m-1">
        <p>
            {{ documentFile }}
        </p>
    </div>
    <!-- VIDEO -->
    <video #videoPlayer controls preload="auto" class="center" [ngClass]="{ hideVideo: !documentType.includes('video') }">
        <source src="documentFile | safe: 'url'" type="video/mp4" />
        Vaš brskalnik ne podpira ogleda videa
    </video>
    <!-- UNKNOWN TYPE -->
    <div
        *ngIf="
            documentType != 'application/pdf' &&
            !documentType.includes('video') &&
            documentType != 'text/plain' &&
            !documentType.includes('image') &&
            !qrSettings
        "
        style="background-color: white; border-radius: 10px"
        class="text-center p-4 col-12 col-md-6 col-xl-3 mx-auto mt-3"
    >
        <div class="row">
            <div class="col-12" i18n="@@media.view.unknown.document">Predogled ni mogoč</div>
            <div class="col-6 mt-2 mx-auto" i18n="@@media.view.download.document">
                <button (click)="downloadFile()" class="btn btn-outline-accent m-btn m-btn--pill btn-block">Prenesi datoteko</button>
            </div>
        </div>
    </div>
    <!-- QR CODE -->
    <div [hidden]="!qrSettings" class="d-flex justify-content-center">
        <qr-code
            #qrCode
            [value]="qrSettings?.url"
            [size]="qrSettings?.size || 400"
            errorCorrectionLevel="M"
            centerImageSrc="undefined"
            centerImageSize="undefined"
            margin="4"
        ></qr-code>
    </div>
</div>
